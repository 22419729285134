<template>
    <Navbar :user="user"/>
    <section class="courses-details-area pt-100 pb-70" v-if="!isLoading">
      <div class="container">
        <div class="courses-details-header">
          <div class="row align-items-center">
            <div class="courses-title">
              <h6 class="text-default">Congraturations You Have Completed </h6>
              <h2>{{ course.title }}</h2>
              <router-link  class="default-btn btn" :to="{ name: 'TestCouresDetails',params: {id: course.id}}">
                <span class="label">Take A Test</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import moment from 'moment'
export default {
 components: { Navbar, Loading },
  data(){
    return {
      isLoading: true,
      subscriptionStatus: 0,
      rating: 4.3,
      course: {},
      user: {},
      token: localStorage.getItem('user_data'),
    }
  },
  methods:{
    learnCourse(id){
      axios.post('https://apitraining.vipawaworks.com/api/course/take_course/' + id).then(response => {
        response.data
        this.$router.push({ name: 'CourseLearn', params: {id: id} })
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    takQuiz(id){
      axios.post('https://apitraining.vipawaworks.com/api/quiz/take_quiz/' + id).then(response => {
        response.data
        this.$router.push({ name: 'CourseLearn', params: {id: id} })
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getSubscriptionStatus(){
      let id = this.$route.params.id
      axios.get('https://apitraining.vipawaworks.com/api/course/get_subscribed_status/' + id).then(response => {
        this.subscriptionStatus = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getCourse(){
      let id = this.$route.params.id
      axios.get('https://apitraining.vipawaworks.com/api/course/show/' + id).then(response => {
        this.course = response.data
        document.title = this.course.title +' - Training'
      }).catch(errors => {
        this.errors = errors.response.data.errors
      }).finally(() => {
        setTimeout(function () {
          this.isLoading =  false
        }.bind(this), 500);
      })
    },
    getCourseContent(){
      let id = this.$route.params.id
      axios.get('https://apitraining.vipawaworks.com/api/course_content/get_contents_with_lessons/' + id).then(response => {
          this.course_content = response.data
      }).catch(errors => {
          this.errors = errors.response.data.errors
      }).finally(() => {
          setTimeout(function () {
              this.isLoading =  false
          }.bind(this), 500);
      })
    },
    getUser(){
        let id = this.$route.params.id
        axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
            this.user = response.data
        }).catch(errors => {
            if (errors.response.status === 401) {
            localStorage.removeItem('user_data')
            this.$router.push({ name: 'Login', query: { redirect: '/courses/completed/'+ id } })
            }
        })
    },
  },
  created(){
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getCourse() 
    this.getCourseContent()
    this.getSubscriptionStatus()
    this.moment = moment
  }
}
</script>

<style>

</style>